import {
  defineStore,
  storeToRefs,
} from 'pinia';
import { API_PREFIX } from '~/constants/apiConfiguration';
import { useAuthStore } from '~/stores/auth';
import { GAMES_TYPES } from '~/constants/general';
import { COOKIE } from '~/constants/storedPropertiesNames';
import type { GamesStateStore } from '~/types/stores/games';
import type { FilterParams } from '~/types/pages/homepage';
import type {
  FetchGamesEndpoint,
  GameByPrettyNameOptions,
  GameByPrettyNameParams,
  GameByTypeOptions,
  GameProvidersOptions,
  GamesDataKey,
  GamesResponse,
  GamesData,
  ProvidersResponseSSR,
} from '~/types/general/game';
import type {
  LaunchGameSrcResponse,
  LaunchGameSrcParams,
} from '~/types/pages/game';

export const useGamesStore = defineStore({
  id: 'games',
  state: (): GamesStateStore => ({
    gameProviders: [],
    allGamesData: {
      categories: [],
      iconsStorage: '',
      items: [],
      providers: [],
      total: 0,
    },
    newGamesData: {
      categories: [],
      iconsStorage: '',
      items: [],
      providers: [],
      total: 0,
    },
    homepageSearchGamesData: {
      categories: [],
      iconsStorage: '',
      items: [],
      providers: [],
      total: 0,
    },
    popularGamesData: {
      categories: [],
      iconsStorage: '',
      items: [],
      providers: [],
      total: 0,
    },
    gameData: {},
    isSeoCrawlerDetected: false,
  }),
  getters: {
    getGameProviders: (state) => state.gameProviders,
    getHomepageSearchGames: (state) => state.homepageSearchGamesData,
    getAllGames: (state) => state.allGamesData,
    getNewGames: (state) => state.newGamesData,
    getPopularGames: (state) => state.popularGamesData,
    getGameData: (state) => state.gameData,
    getAllGamesRemoteStorageHost: (state) => state.allGamesData.iconsStorage,
    getNewGamesRemoteStorageHost: (state) => state.newGamesData.iconsStorage,
    getPopularGamesRemoteStorageHost: (state) => state.popularGamesData.iconsStorage,
    getIsSeoCrawlerDetected: (state) => state.isSeoCrawlerDetected,
  },
  actions: {
    /**
     * @section Actions related for All Games
     */
    async fetchGamesByType(
      filterParams: FilterParams,
      gamesType: string,
      appendGames: boolean,
    ) {
      let endpoint: FetchGamesEndpoint;
      let gamesDataKey: GamesDataKey;

      switch (gamesType) {
        case GAMES_TYPES.all:
          gamesDataKey = 'allGamesData';
          endpoint = 'info';
        break;
        case GAMES_TYPES.new:
          gamesDataKey = 'newGamesData';
          endpoint = 'new';
        break;
        case GAMES_TYPES.homepageSearch:
          gamesDataKey = 'homepageSearchGamesData';
          endpoint = 'info';
        break;
        case GAMES_TYPES.popular:
          gamesDataKey = 'popularGamesData';
          endpoint = 'popular';
        break;
        default:
          throw new Error(`Invalid gamesType: ${gamesType}`);
      }

      try {
        const { data } = await useMyFetch().get(
          `${API_PREFIX.lobby}/${endpoint}`,
          { query: filterParams },
        ) as GamesResponse;

        if (appendGames) {
          this.$state[gamesDataKey].items = [...this.$state[gamesDataKey].items, ...data.items];
        } else {
          this.$state[gamesDataKey] = { ...data };
        }
      } catch (error) {
        throw(error);
      }
    },
    async fetchGamesByTypeForSSR(
      filterParams: FilterParams,
      gamesType: string,
    ) {
      let endpoint: FetchGamesEndpoint;
      let gamesDataKey: GamesDataKey;

      switch (gamesType) {
        case GAMES_TYPES.all:
          gamesDataKey = 'allGamesData';
          endpoint = 'info';
        break;
        case GAMES_TYPES.new:
          gamesDataKey = 'newGamesData';
          endpoint = 'new';
        break;
        default:
          throw new Error(`Invalid gamesType: ${gamesType}`);
      }
      const url = `${useBaseAppUrl().api}${API_PREFIX.lobby}/${endpoint}`;
      const startTime = Date.now();
      const options: GameByTypeOptions = { query: filterParams };
      const { $ssrRequestLogger } = useNuxtApp();

      if (this.isSeoCrawlerDetected) {
        options.headers = { 'X-Bot-Verified': 'true' };
      }

      try {
        const data = await $fetch(url, options) as GamesData;

        this.$state[gamesDataKey] = { ...data };
        if (import.meta.server) {
          $ssrRequestLogger({
            url,
            startTime,
            success: true,
            result: data,
            requestHeaders: options.headers,
          });
        }
      } catch (error) {
        if (import.meta.server) {
          $ssrRequestLogger({
            url,
            startTime,
            success: false,
            result: error,
          });
        }
      }
    },
    clearHomepageSearchGamesData () {
      this.homepageSearchGamesData = {
        categories: [],
        iconsStorage: '',
        items: [],
        providers: [],
        total: 0,
      };
    },
    async fetchGameProvidersForSSR() {
      const url = `${useBaseAppUrl().api}${API_PREFIX.lobby}/providers`;
      const startTime = Date.now();
      const jSessionIdFECookie = useCookie(COOKIE.jSessionIdFE);
      const options: GameProvidersOptions = {};
      const { $ssrRequestLogger } = useNuxtApp();

      if (jSessionIdFECookie.value) {
        options.headers = { cookie: `SESSION=${jSessionIdFECookie.value};` };
      }
      try {
        const data = await $fetch(url, options) as ProvidersResponseSSR;

        this.gameProviders = data.return.providers;
        if (import.meta.server) {
          $ssrRequestLogger({
            url,
            startTime,
            success: true,
            result: data,
            requestHeaders: options.headers,
          });
        }
      } catch (error) {
        if (import.meta.server) {
          $ssrRequestLogger({
            url,
            startTime,
            success: false,
            result: error,
          });
        }
      }
    },
    clearGameProviders() {
      this.gameProviders = [];
    },
    /**
     * @section Actions related for Specific Game
     */
    async fetchGameByPrettyName({
      prettyName,
      platform,
    }: GameByPrettyNameParams) {
      try {
        const { data } = await useMyFetch().get(
          `${API_PREFIX.lobby}/infoByPrettyName`,
          {
            query: {
              prettyName,
              platform,
            },
          },
        ) as GamesResponse;

        this.gameData = data?.items?.[0]
          ? {
            ...data?.items?.[0],
            launchSrc: '',
          }
          : {};
      } catch (error) {
        throw(error);
      }
    },
    async fetchGameByPrettyNameForSSR(
      {
        prettyName,
        platform,
      }: GameByPrettyNameParams,
    ) {
      const url = `${useBaseAppUrl().api}${API_PREFIX.lobby}/infoByPrettyName`;
      const startTime = Date.now();
      const jSessionIdFECookie = useCookie(COOKIE.jSessionIdFE);
      const options: GameByPrettyNameOptions = {
        params: {
          prettyName,
          platform,
        },
      };
      const { $ssrRequestLogger } = useNuxtApp();

      if (jSessionIdFECookie.value) {
        options.headers = { cookie: `SESSION=${jSessionIdFECookie.value};` };
      }
      try {
        const data = await $fetch(url, options) as GamesData;

        this.gameData = data?.items?.[0]
          ? {
            ...data?.items?.[0],
            launchSrc: '',
          }
          : {};
        if (import.meta.server) {
          $ssrRequestLogger({
            url,
            startTime,
            success: true,
            result: data,
            requestHeaders: options.headers,
          });
        }
      } catch (error) {
        if (import.meta.server) {
          $ssrRequestLogger({
            url,
            startTime,
            success: false,
            result: error,
          });
        }
      }
    },
    async fetchLaunchGameSrc({
      gameCode,
      gameProviderId,
      mode,
    }: LaunchGameSrcParams) {
      const authStore = useAuthStore();
      const { getUserData } = storeToRefs(authStore);
      const lang = getUserData.value.locale;

      try {
        const { data } = await useMyFetch().post(`${API_PREFIX.game}/launch`, {
          query: { lang },
          body: {
            gameCode,
            gameProviderId,
            mode,
          },
        }) as LaunchGameSrcResponse;

        this.gameData.launchSrc = data.return;
      } catch (error) {
        throw(error);
      }
    },
    setSeoCrawlerDetected(payload: boolean){
      this.isSeoCrawlerDetected = payload;
    },
  },
});
